import {
  clearNotifications,
  eventWrapper,
  getCookie,
  markAsReceived,
  notifyMe,
  scrollBottom2,
  sortMessageArray,
} from "@/common";
import { eventBus } from "@/common/eventBus";
import store from "..";
import type { Message, WatchRequest } from "@/common/interfaces";

interface StoreState {
  sharedImage: null | { file: File; url: string };
}

interface AcceptedWatchRequest extends WatchRequest {
  userId: string;
}

store.registerModule("appData", {
  state: (): StoreState => ({
    sharedImage: null,
  }),
  getters: {
    sharedImage: (state) => state.sharedImage,
  },
  mutations: {
    setSharedImage(state, data) {
      state.sharedImage = data;
    },
    clearSharedImage(state) {
      state.sharedImage = null;
    },
  },
  actions: {
    acceptedWatchRequestHandler(context, data: AcceptedWatchRequest) {
      // TODO: in the future we should add a way for us to confirm that we're ready?
      if (data.userId === context.rootState.user?.id) {
        return;
      }
      context.commit("loadYTComponent", data.friendship_id);
      context.commit("enterYTSession", data.friendship_id);
      context.commit("updateCurrentYTSession", data);
      context.commit("setCurrentChat", data.friendship_id);
      eventBus.$emit("newWatchSession");
    },
    attachListeners: (context) => {
      context.rootState.socket?.on("reconnect", () => {
        context.rootState.socket?.emit("readyForSignals", {
          sessionUid: context.rootState.sessionUid,
          token: getCookie("token"),
          data: context.rootState.user,
        });
        context.rootState.checkinActive = true;
        const checkinData: Record<string, Message> = {};
        // TODO: kickoff the checkin for the current chat first
        context.rootState.friendShips
          ?.map(({ _id }) => _id)
          .forEach((id) => {
            if (context.rootState.messages?.[id]) {
              checkinData[id] =
                context.rootState.messages[id][
                  context.rootState.messages[id].length - 1
                ];
              // we will need to ask for the updated status of messages that arent read, at this point i will
              // assume that this is almost always going to be a small number so we can simply send all the msgIds
              // in order to make the query simple
              /** no */
              // checkinData[id].unread = [];
              // for (
              //   let i = context.rootState.messages[id].length - 1;
              //   i > context.rootState.messages[id].length - 50 && i <= 0;
              //   i--
              // ) {
              //   if (context.rootState.messages[id][i].status !== "read") {
              //     checkinData[id].unread.push(
              //       context.rootState.messages[id][i].msgId
              //     );
              //   }
              // }
            }
          });
        context
          .dispatch("emitEvent", {
            eventName: "masCheckin",
            data: checkinData,
          })
          .then((newMessages: Record<string, Message[]>) => {
            /** @todo scrolling behaviour doesnt feel like it should be here */
            let shouldScroll;
            const chatBody = document.querySelector(`.chat__main`);
            /** figure out if we should scroll to force it later since
             *  our function wont work well with multiple new messages */
            if (chatBody) {
              shouldScroll = scrollBottom2({
                element: chatBody,
                force: false,
                test: true,
              });
            }
            for (const friendship_id in newMessages) {
              newMessages[friendship_id].sort(sortMessageArray);
              newMessages[friendship_id] = newMessages[friendship_id].map(
                (message) => {
                  if (message.fromId !== context.rootState.user?.id) {
                    if (context.getters.isInChat == friendship_id) {
                      /**
                       * no need to update the dom nodes because these are messages
                       * received by me so we dont show the status this is mainly
                       * for the unread count
                       */
                      message.status = "read";
                    } else {
                      context.commit("incUnread", { friendship_id });
                    }
                  }
                  return message;
                }
              );
              context.commit("addBulkPreSortedMessages", {
                friendship_id,
                messages: newMessages[friendship_id],
              });
              markAsReceived(friendship_id, [
                newMessages[friendship_id][0].createdAt,
                newMessages[friendship_id][
                  newMessages[friendship_id].length - 1
                ].createdAt,
              ]);
              context.commit("updateLastMessage", {
                friendship_id,
                lastMessage:
                  newMessages[friendship_id][
                    newMessages[friendship_id].length - 1
                  ],
              });
            }
            if (shouldScroll && chatBody)
              scrollBottom2({
                element: chatBody,
                force: true,
                test: false,
              });
            context.rootState.checkinActive = false;
          })
          .catch(() => {
            console.log;
            context.rootState.checkinActive = false;
          });
      });
      context.rootState.socket?.on(
        "newMessage",
        eventWrapper("newMessage", async (data) => {
          await context.dispatch("socketNewMessageHandler", data);
        })
      );
      context.rootState.socket?.on(
        "received",
        eventWrapper(
          "received",
          async (data) => await context.dispatch("socketReceivedHandler2", data)
        )
      );
      context.rootState.socket?.on(
        "sweep",
        eventWrapper(
          "sweep",
          async (data) => await context.dispatch("socketSweepHandler2", data)
        )
      );
      context.rootState.socket?.on(
        "newFriend",
        eventWrapper(
          "newFriend",
          async (data) => await context.dispatch("socketNewFriendHandler", data)
        )
      );
      context.rootState.socket?.on(
        "newFriendRequest",
        eventWrapper(
          "newFriendRequest",
          async (data) =>
            await context.dispatch("socketNewFriendRequestHandler", data)
        )
      );
      context.rootState.socket?.on("pauseVideo", eventWrapper("pauseVideo"));
      context.rootState.socket?.on("playVideo", eventWrapper("playVideo"));
      context.rootState.socket?.on("nextVideo", eventWrapper("nextVideo"));
      context.rootState.socket?.on(
        "previousVideo",
        eventWrapper("previousVideo")
      );
      context.rootState.socket?.on(
        "playListUpdated",
        eventWrapper("playListUpdated")
      );
      context.rootState.socket?.on(
        "acceptedWatchRequest",
        eventWrapper("acceptedWatchRequest")
      );
      context.rootState.socket?.on(
        "watchSessRequest",
        eventWrapper("watchSessRequest")
      );
      context.dispatch("addOneTimeListener", {
        customName: "attachListeners",
        event: "acceptedWatchRequest",
        handler: (data: AcceptedWatchRequest) =>
          context.dispatch("acceptedWatchRequestHandler", data),
      });
    },
    socketNewMessageHandler: (context, { token, data }) => {
      if (token === getCookie("token")) {
        return;
      }
      /** if we get a message about the other persons typing */
      if (data.type === "typing") {
        const typingIndicator = document.querySelector(
          ".typing"
        ) as HTMLElement;
        // if its saying the person has started typing
        if (data.status === "start") {
          context.commit("showTyping", data.friendship_id);
          if (data.friendship_id === context.getters.currChatFriendshipId) {
            typingIndicator.classList.remove("op");
          }
          // if its saying the person has stopped typing
        } else if (data.status === "stop") {
          context.commit("hideTyping", data.friendship_id);
          if (data.friendship_id === context.getters.currChatFriendshipId) {
            typingIndicator.classList.add("op");
          }
        }
        return;
      }
      if (context.getters.showPopupNotif) {
        context.rootState.messageNotification.success(
          `${data.from}: ${data.text}`
        );
      }
      if (context.rootState.enableSoundNotif) {
        try {
          context.getters.notifAudio.play();
          // eslint-disable-next-line no-empty
        } catch {}
      }
      if (
        context.rootState.user?.pushEnabled &&
        !context.rootState.disableNotif
      ) {
        notifyMe(data);
      }
      const read = context.getters.isInChat == data.friendship_id;

      let messageStatus;
      /**
       * @todo have a queue where we can store events that are to update the message
       * status, where these events come in before the actual message
       */
      if (data.fromId === context.rootState.user?.id) {
        messageStatus = "sent";
      } else {
        messageStatus = read ? "read" : "receieved";
      }
      const newMessage = {
        friendship_id: data.friendship_id,
        message: {
          createdAt: data.createdAt,
          from: data.from,
          text: data.text,
          msgId: data.msgId,
          _id:
            data.fromId === context.rootState.user?.id
              ? data.Ids.senderId
              : data.Ids.receiverId,
          quoted: data.quoted,
          fromId: data.fromId,
          type: data.type,
          media: data.media,
          meta: data.meta,
          linkPreview: data.linkPreview,
          url: data.url,
          /** @todo this does not go with the typical schema values for status */
          status: messageStatus,
        },
      };
      context.rootState.updateQueue.forEach((event) => {
        if (
          event.msgId === newMessage.message.msgId &&
          newMessage.message.status !== "read"
        ) {
          newMessage.message.status = event.read ? "read" : "received";
        }
      });
      context.commit("appendMessageToChat", newMessage);
      context.commit("updateLastMessage", {
        friendship_id: data.friendship_id,
        lastMessage: data,
      });
      /** let the next user know that this message is green ticked */
      /** if we are signed in on multiple devices only tick if the message isnt coming from us */
      if (data.fromId !== context.rootState.user?.id) {
        context.rootState.socket?.emit("gotMessage", {
          friendship_id: data.friendship_id,
          token: getCookie("token"),
          msgId: data.msgId,
          createdAt: data.createdAt,
          read,
        });
      }
    },
    appFocused: (context) => {
      if (context.getters.currChatFriendshipId) {
        context.commit("markLocalChatMessagesAsRead", {
          friendship_id: context.getters.currChatFriendshipId,
        });
        const messages =
          context.rootState.messages?.[context.getters.currChatFriendshipId];
        if (messages && messages.length > 0) {
          markAsReceived(context.getters.currChatFriendshipId, [
            messages[0].createdAt,
            messages[messages.length - 1].createdAt,
          ]);
        }
      }
    },
    setCurrentChat({ rootState, getters, commit }, friendship_id) {
      commit("setCurrentChat", friendship_id);
      if (friendship_id === "") {
        rootState.currChatMessages = [];
        return;
      }
      if (getters.isInChat === friendship_id) {
        /** @todo tell the server to mark all these as read  */
        commit("markLocalChatMessagesAsRead", {
          friendship_id,
        });
        const friendShipMessages = rootState.messages?.[friendship_id];
        if (friendShipMessages && Number(friendShipMessages?.length) > 0) {
          markAsReceived(friendship_id, [
            friendShipMessages[0].createdAt,
            friendShipMessages[friendShipMessages.length - 1].createdAt,
          ]);
        }
        clearNotifications({ tag: friendship_id });
      }
      rootState.currChatMessages = rootState.messages?.[friendship_id] || [];
    },
  },
});
