import { eventBus } from "@/common/eventBus";
import store from "..";
import Peer from "peerjs";
import type { MediaConnection } from "peerjs";
import { errorReport } from "@/common";
store.registerModule("vidCall", {
  state: {
    callerAppId: null as null | string,
    peer: null as Peer | null,
    peerId: "",
    stream: null as null | MediaStream,
    currentCall: null as null | MediaConnection,
    callAccepted: false,
    loadingMedia: null as null | Promise<null | MediaStream>,
    videoVisible: true,
    isMuted: false,
    callId: null as null | string,
    hideCall: false,
    callToBeAcknowledged: false,
    ringerTimeout: null as any,
    callOrigin: null as null | "outgoing" | "incoming",
  },
  getters: {
    callerAppId: (state) => state.callerAppId,
    peerId: (state) => state.peerId,
    stream: (state) => state.stream,
    currentCall: (state) => state.currentCall,
    callAccepted: (state) => state.callAccepted,
    isMuted: (state) => state.isMuted,
    videoVisible: (state) => state.videoVisible,
    hideCall: (state) => state.hideCall,
    callToBeAcknowledged: (state) => state.callToBeAcknowledged,
  },
  mutations: {
    cleanupCall(state) {
      state.currentCall?.removeAllListeners();
      state.currentCall?.close();
      state.currentCall = null;
      state.callerAppId = null;
      state.callAccepted = false;
      state.stream?.getTracks().forEach((track) => track.stop());
      state.stream = null;
      state.callId = null;
      state.callToBeAcknowledged = false;
      if (state.ringerTimeout !== null) {
        clearTimeout(state.ringerTimeout);
        state.ringerTimeout = null;
      }
    },
    cleanupPeerData(state) {
      state.peerId = "";
      state.peer = new Peer();
    },
    ackCall(state) {
      state.callToBeAcknowledged = false;
    },
    toggleAudio(state, force?: boolean) {
      if (state.currentCall) {
        state.isMuted = force ?? !state.isMuted;
        state.stream?.getAudioTracks().forEach((track) => {
          // put this first to make sure the state dictates the status of the audio
          track.enabled = state.isMuted;
        });
        try {
          navigator.mediaSession.setMicrophoneActive(state.isMuted);
        } catch (error) {
          console.log(error);
        }
      }
    },
    toggleVideo(state, force?: boolean) {
      if (state.currentCall) {
        state.videoVisible = force ?? !state.videoVisible;
        state.stream?.getVideoTracks().forEach((track) => {
          track.enabled = state.videoVisible;
        });
        try {
          navigator.mediaSession.setCameraActive(state.videoVisible);
        } catch (error) {
          console.log(error);
        }
      }
    },
    toggleCall(state, force?: boolean) {
      state.hideCall = force ?? !state.hideCall;
    },
  },
  actions: {
    attachPeerListeners(context) {
      context.state.peer?.on("open", (id) => {
        context.state.peerId = id;
        eventBus.$emit("peer-initialized");
      });
      context.state.peer?.on("error", (err) => {
        errorReport(err, { caller: "peer error listener" });
        console.log(err);
      });
      context.state.peer?.on("close", (...args) => {
        // i dont think this is supposed to happen but at least log it?
        console.log("peer closed", args);
      });
      context.state.peer?.on("disconnected", (...args) => {
        console.log("peer disconnected", args);
      });
      context.state.peer?.on("call", async (call) => {
        try {
          console.log("in:peercall");
          context.state.currentCall = call;
          const stream = await context.dispatch("getOrLoadMediaStream");
          call.answer(stream);
          context.dispatch("attachCallListeners");
        } catch (e) {
          console.log(e);
          errorReport(e, { caller: "peer call listener" });
        }
      });
    },
    setUpApp(context) {
      // this may very well be a waste of resorces especially if the user never uses the video call feature
      context.state.peer = new Peer();
      context.dispatch("attachPeerListeners");

      eventBus.$on("mute-audio", () => {
        context.commit("toggleAudio", false);
      });

      eventBus.$on("unmute-audio", () => {
        context.commit("toggleAudio", true);
      });

      eventBus.$on("hide-call", () => {
        context.commit("toggleCall", true);
      });

      eventBus.$on("show-call", () => {
        context.commit("toggleCall", false);
      });

      const addStartupSocketListeners = () => {
        context.rootState.socket?.on(
          "call",
          ({ userId, friendship_id, _id }) => {
            try {
              console.log("call");

              if (userId === context.rootState.user?.id) return;

              if (context.state.callerAppId) {
                return context.dispatch("emitEvent", {
                  eventName: "callBusy",
                  data: {
                    friendship_id,
                    userId: context.rootState.user?.id,
                    callId: _id,
                  },
                });
              }

              // for persistant signals this triggers before the vid call component
              eventBus.$emit("incoming-call", userId);
              context.state.callerAppId = userId;
              context.state.callId = _id;
              context.state.callOrigin = "incoming";
              // fix for the above comment
              context.state.callToBeAcknowledged = true;
            } catch (error) {
              errorReport(error, { caller: "socket call listener" });
            }
          }
        );
        context.rootState.socket?.on("missedCall", ({ friendship_id }) => {
          context.rootState.messageNotification.error(
            "missed call from " +
              context.rootState.friendShips?.find(
                ({ _id }) => friendship_id === _id
              )?.username
          );
        });
        context.rootState.socket?.on("endCall", ({ callId }) => {
          console.log("call-ended");

          if (context.state.callId === callId) {
            context.commit("cleanupCall");
            eventBus.$emit("call-ended");
          }
        });
        context.rootState.socket?.on(
          "peerIdForCall",
          async ({ peerId: friendPeerId, userId, callId }) => {
            try {
              if (
                userId === context.rootState.user?.id ||
                callId !== context.state.callId
              ) {
                console.log("data doesnt match", {
                  userId,
                  callId,
                  statecallId: context.state.callId,
                  stateuserId: context.rootState.user?.id,
                });
                return;
              }
              console.log("in:peerIdForCall");
              const stream = await context.dispatch("getOrLoadMediaStream");

              // i need to make sure that the peer is connected before i can call
              if (context.state.peer?.disconnected) {
                await context.dispatch("connectToPeerServer");
              }
              context.state.currentCall =
                context.state.peer?.call(friendPeerId, stream) || null;
              console.log("current call", context.state.currentCall);
              context.dispatch("attachCallListeners");
              eventBus.$emit("call-accepted");
            } catch (e) {
              console.log(e);
              errorReport(e, { caller: "peerIdForCall listener" });
            }
          }
        );
        context.rootState.socket?.on("callDeclined", ({ userId, callId }) => {
          console.log("callDeclined");
          if (
            userId === context.rootState.user?.id ||
            callId !== context.state.callId
          )
            return;
          context.commit("cleanupCall");
          eventBus.$emit("call-declined");
        });
        context.rootState.socket?.on("callBusy", ({ userId, callId }) => {
          console.log("callBusy");
          if (
            userId === context.rootState.user?.id ||
            callId !== context.state.callId
          )
            return;
          context.commit("cleanupCall");
          eventBus.$emit("call-busy");
        });
      };
      if (!context.rootState.socket) {
        eventBus.$once("socket-initialized", addStartupSocketListeners);
      } else {
        addStartupSocketListeners();
      }
    },
    connectToPeerServer(context) {
      console.log("connecting to peer server");
      const initialized = new Promise((resolve) => {
        eventBus.$once("peer-initialized", () => {
          resolve(true);
        });
      });
      context.state.peer = new Peer();
      context.dispatch("attachPeerListeners");
      return initialized;
    },
    attachCallListeners(context) {
      console.log("attaching call listeners");
      context.state.currentCall?.on("stream", (theirStrem: MediaStream) => {
        console.log("received remote stream", theirStrem);
        eventBus.$emit("remote-stream", theirStrem);
      });
      context.state.currentCall?.on("close", () => {
        console.log("close");
        context.commit("cleanupCall");
      });
    },
    async acceptCall(context) {
      if (context.state.peer?.disconnected) {
        await context.dispatch("connectToPeerServer");
      }

      context.dispatch("emitEvent", {
        eventName: "peerIdForCall",
        data: {
          peerId: context.state.peerId,
          userId: context.rootState.user?.id,
          friendship_id: context.rootState.friendShips?.find(
            ({ friendId }) => friendId === context.state.callerAppId
          )?._id,
          callId: context.state.callId,
        },
      });
      context.state.callAccepted = true;
      eventBus.$emit("call-accepted");
    },
    declineCall(context) {
      store.dispatch("emitEvent", {
        eventName: "callDeclined",
        data: {
          userId: context.rootState.user?.id,
          friendship_id: context.rootState.friendShips?.find(
            ({ friendId }) => friendId === context.state.callerAppId
          )?._id,
          callId: context.state.callId,
        },
      });
      context.commit("cleanupCall");
      eventBus.$emit("call-declined");
    },
    async call(context, data: { friendship_id: string; friendId: string }) {
      try {
        const CALL_TIMEOUT = 90000;
        // I think there should be enough time to reconnect here before the user accepts and we have to
        // call on the actual peer
        if (context.state.peer?.disconnected) {
          await context.dispatch("connectToPeerServer");
        }

        // just get the peerId asynchronously when the user connects
        console.log("calling user...");

        // it's best to do this here because otherwise if the user switches tabs before the frieend accepts the
        // call then there will be no video until the user goes back to that tab
        context.dispatch("getOrLoadMediaStream");

        context.state.callId = await context.dispatch("emitEvent", {
          eventName: "call",
          data: {
            friendship_id: data.friendship_id,
            userId: context.rootState.user?.id,
            friendId: data.friendId,
            ttl: CALL_TIMEOUT,
          },
        });
        context.state.callerAppId = data.friendId;
        context.state.callOrigin = "outgoing";
        eventBus.$emit("outgoing-call");
        context.state.ringerTimeout = setTimeout(() => {
          if (!context.state.currentCall) {
            console.log("call ended from ringer timeout");
            context.dispatch("endCall");
          }
        }, CALL_TIMEOUT);
      } catch (e) {
        console.log(e);
        errorReport(e, { caller: "call function" });
      }
    },
    endCall(context) {
      context.dispatch("emitEvent", {
        eventName: "endCall",
        data: {
          friendship_id: context.rootState.friendShips?.find(
            ({ friendId }) => friendId === context.state.callerAppId
          )?._id,
          userId: context.rootState.user?.id,
          callId: context.state.callId,
        },
      });
      context.commit("cleanupCall");
      eventBus.$emit("call-ended");
    },
    getOrLoadMediaStream(context) {
      console.log("getting media stream");
      if (context.state.stream) return context.state.stream;
      if (context.state.loadingMedia) return context.state.loadingMedia;

      context.state.loadingMedia = navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: true,
        })
        .catch((err) => {
          console.log(err);
          return navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true,
          });
        })
        .then((stream) => {
          context.state.stream = stream;
          context.state.loadingMedia = null;

          try {
            navigator.mediaSession.setMicrophoneActive(context.state.isMuted);
            navigator.mediaSession.setCameraActive(context.state.videoVisible);
          } catch (error) {
            console.log("error setting media call session active");
          }

          try {
            navigator.mediaSession.setActionHandler("togglemicrophone", () => {
              console.log('> User clicked "Toggle Mic" icon.');
              context.commit("toggleAudio");
            });
          } catch (error) {
            console.log(
              'Warning! The "togglemicrophone" media session action is not supported.'
            );
          }

          try {
            navigator.mediaSession.setActionHandler("togglecamera", () => {
              console.log('> User clicked "Toggle Camera" icon.');
              context.commit("toggleVideo");
            });
          } catch (error) {
            console.log(
              'Warning! The "togglecamera" media session action is not supported.'
            );
          }

          try {
            navigator.mediaSession.setActionHandler("hangup", () => {
              console.log("hanging up from action handler");
              context.dispatch("endCall");
            });
          } catch (error) {
            console.log(
              'Warning! The "hangup" media session action is not supported.'
            );
          }

          eventBus.$emit("local-stream", stream);
          return stream;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
      return context.state.loadingMedia;
    },
  },
});
