<template>
  <div style="color: var(--main-text)">
    <!-- <button @click="() => call()" class="btn text-white">
      <SVGIcon class="mysvg" iconName="camera-video-fill" />
    </button> -->
    <main
      :data-shown="`${!!currentCall && !hideCall}`"
      class="call-container"
      ref="call-container"
    >
      <div class="theirs">
        <video ref="theirVid"></video>
      </div>
      <div class="mine">
        <video ref="myVid" muted></video>
      </div>

      <div class="controls">
        <button class="btn btn-danger rounded-circle" @click="toggleAudio">
          <SVGIcon v-show="isMuted" iconName="mic" />
          <SVGIcon v-show="!isMuted" iconName="mic-mute" />
        </button>
        <button class="btn btn-danger rounded-circle" @click="toggleVideo">
          <SVGIcon v-show="!videoVisible" iconName="camera-video" />
          <SVGIcon v-show="videoVisible" iconName="camera-video-off" />
        </button>
        <button
          class="btn me-1 btn-danger btn-lg rounded-circle close"
          v-if="currentCall"
          @click="endCall"
        >
          <SVGIcon iconName="telephone" />
        </button>
        <button
          class="btn btn-danger btn-lg rounded-circle"
          v-if="currentCall"
          @click="togglePictureInPicture"
        >
          <SVGIcon iconName="shift" />
        </button>
      </div>
    </main>
    <div
      class="ring-toast toast align-items-center text-white bg-primary border-0"
      ref="ringToast"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="d-flex">
        <div class="toast-body">
          Ringing...
          <button class="btn btn-danger" @click="endCall">cancel</button>
        </div>
      </div>
    </div>
    <div
      ref="callToast"
      class="toast call-toast"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-body">
        {{
          friends?.find(({ friendId }) => friendId === callerAppId)?.username
        }}
        is calling...
        <div class="d-flex mt-2 pt-2 border-top justify-content-end">
          <button
            type="button"
            class="btn btn-success btn-sm"
            data-bs-dismiss="toast"
            @click="acceptCall"
          >
            Answer
          </button>
          <button
            @click="declineCall"
            type="button"
            class="btn btn-danger btn-sm ms-3"
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import store from "@/store";
import Vue from "vue";
import type { Friendship, User } from "@/common/interfaces";
import { Toast } from "bootstrap";
import { eventBus } from "@/common/eventBus";
import type { MediaConnection } from "peerjs";
import { dragElement, errorReport } from "@/common";
import SVGIcon from "./SVGIcon.vue";
export default Vue.component("ViewImageModal", {
  components: { SVGIcon },
  props: { comp: Boolean, friendship_id: String, friendId: String },
  destroyed() {
    store.commit("cleanupCall");
    // may need to specify reference to listeners to stop this from tampering with other components' listeners
    eventBus.$off("incoming-call");
    eventBus.$off("call-ended");
    eventBus.$off("call-accepted");
    eventBus.$off("local-stream");
    eventBus.$off("remote-stream");
  },
  created() {
    eventBus.$on("incoming-call", () => {
      this.getToast("callToast")?.show();
      store.commit("ackCall");
    });
    eventBus.$on("outgoing-call", () => {
      this.getToast("ringToast")?.show();
    });

    eventBus.$on("call-ended", () => {
      // maybe the parent should close this components
      this.getToast("callToast")?.hide();
      this.getToast("ringToast")?.hide();
    });
    eventBus.$on("call-accepted", () => {
      this.getToast("callToast")?.hide();
      this.getToast("ringToast")?.hide();
    });
    eventBus.$on("call-declined", () => {
      this.getToast("ringToast")?.hide();
    });
    eventBus.$on("local-stream", (stream: MediaStream) => {
      this.addToStream("myVid", stream);
    });
    eventBus.$on("remote-stream", (stream: MediaStream) => {
      console.log("responding remote stream");
      this.addToStream("theirVid", stream);
    });
  },
  mounted() {
    if (this.callToBeAcknowledged) {
      this.getToast("callToast")?.show();
      store.commit("ackCall");
    }
    const callContainer = this.$refs["call-container"];
    callContainer && dragElement(callContainer as HTMLElement);
  },
  methods: {
    acceptCall() {
      store.dispatch("acceptCall");
      this.getToast("callToast")?.hide();
    },
    togglePictureInPicture() {
      try {
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture();
        } else if (document.pictureInPictureEnabled) {
          (this.$refs.theirVid as HTMLVideoElement)?.requestPictureInPicture();
        }
      } catch (error) {
        errorReport(error, { from: "requestPictureInPicture" });
      }
    },
    toggleAudio() {
      store.commit("toggleAudio");
    },
    toggleVideo() {
      store.commit("toggleVideo");
    },
    getToast(name: string) {
      return Toast?.getOrCreateInstance(this.$refs[name] as HTMLDivElement, {
        autohide: false,
      });
    },
    call() {
      // just get the perrId asynchronously when the user connects
      store.dispatch("call", {
        friendship_id: this.friendship_id,
        friendId: this.friendId,
      });
      this.getToast("ringToast")?.show();
    },
    endCall() {
      store.dispatch("endCall");
      this.getToast("ringToast")?.hide();
    },
    declineCall() {
      store.dispatch("declineCall");
      this.getToast("callToast")?.hide();
    },
    addToStream(vid: string, stream: MediaStream) {
      const vidEl = this.$refs[vid] as HTMLVideoElement;
      console.log("adding to stream", vidEl);

      if (vidEl) {
        vidEl.srcObject = stream;
        vidEl.addEventListener("loadedmetadata", () => {
          vidEl.play();
        });
      }
    },
    adjustVolume(vid: string, target: EventTarget | null) {
      (this.$refs[vid] as HTMLVideoElement).volume =
        Number((target as HTMLInputElement).value) / 100;
    },
  },
  computed: {
    friends: (): Friendship[] => store.getters.friendShips,
    user: (): User => store.getters.user,
    callerAppId: (): string => store.getters.callerAppId,
    isMuted: (): boolean => store.getters.isMuted,
    videoVisible: (): boolean => store.getters.videoVisible,
    hideCall: (): boolean => store.getters.hideCall,
    currentCall: (): null | MediaConnection => store.getters.currentCall,
    callToBeAcknowledged: (): boolean => store.getters.callToBeAcknowledged,
  },
});
</script>
<style lang="scss" scoped>
video {
  object-fit: cover;
  background-color: gray;
  border-radius: 1rem;
}

.call-container {
  display: block;
  position: fixed;
  z-index: 3;
  border-radius: 1rem;
  width: min-content;
  height: min-content;
  top: 10vh;
  left: 10vw;
  overflow: hidden;
  clip-path: circle(0%);
  transition: clip-path 0.3s ease-out;

  &:hover .controls {
    display: flex;
  }

  &[data-shown="true"] {
    // display: block;
    clip-path: circle(100%);
  }
  // :picture-in-picture is still experimental and not yet supported by all browsers
  &:has(:picture-in-picture),
  &[data-shown="false"] {
    clip-path: circle(0%);
  }
}

.controls {
  display: none;
  position: absolute;
  width: 100%;
  justify-content: center;
  bottom: 1rem;

  .btn {
    width: 60px;
    margin: 0rem 0.5rem;
  }

  svg.bi {
    width: 24px;
    height: 24px;
  }
}

.close {
  svg {
    transform: rotateX(180deg) rotateZ(-45deg);
  }
}

.mine {
  inset: unset;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 30%;
  height: min-content;
  video {
    width: 100%;
  }
}

.theirs {
  video {
    max-width: 80vw;
    max-height: 80vh;
    object-fit: contain;
  }
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0.5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.toast {
  animation: tilt-shaking 0.25s infinite;
  margin: 1rem;
}

.toast:not(.showing):not(.show) {
  display: none;
}

.ring-toast,
.call-toast {
  z-index: 1;
  height: min-content;
  position: fixed;
  text-align: center;
  top: 0px;
  left: 0px;
}

.firebase-emulator-warning {
  display: none !important;
}
@keyframes hide {
  0% {
    clip-path: circle(100%);
  }
  100% {
    clip-path: circle(0%);
  }
}

@keyframes show {
  0% {
    clip-path: circle(0%);
  }
  100% {
    clip-path: circle(100%);
  }
}
</style>
