<template>
  <div>
    <VidCall :comp="true" />
    <router-view />
  </div>
</template>
<script lang="ts">
import VidCall from "./components/VidCall2.vue";

export default {
  components: { VidCall },
};
</script>

<style lang="scss">
.no-overscroll {
  overscroll-behavior: contain;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
