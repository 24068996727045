import Vue from "vue";
import type { Friendship } from "./interfaces";

export const eventBus = new Vue({
  methods: {
    dataLoaded() {
      this.$emit("loaded");
    },
    newFriendship(data: {
      requestAccepted: boolean;
      friendshipData: Friendship;
    }) {
      this.$emit("newFriend", data);
    },
  },
});
