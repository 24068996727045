<template>
  <span ref="container" v-html="svgContent"></span>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.component("SVGIcon", {
  props: ["rawSVG", "iconName"],
  created() {
    fetch("/assets/img/" + this.iconName + ".svg").then(
      async (res) => (this.svgContent = await res.text())
    );
  },
  watch: {
    iconName(newVal, oldVal) {
      if (newVal !== oldVal)
        fetch("/assets/img/" + this.iconName + ".svg").then(
          async (res) => (this.svgContent = await res.text())
        );
    },
  },
  data: () => {
    return {
      svgContent: "",
    };
  },
});
</script>
